var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-forms" },
    [
      _c("div", { staticClass: "toolbar justify-content-between" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Admin" },
            on: { click: _vm.clickBack }
          })
        ]),
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.refresh,
            attrs: { title: "Refresh" },
            on: { click: _vm.refreshList }
          })
        ])
      ]),
      _c(
        "div",
        { staticClass: "list-containter" },
        [
          _c(
            "b-card",
            [
              _c("b-card-header", [_c("h2", [_vm._v("Forms Data Admin")])]),
              _c(
                "b-card-body",
                [
                  _c("AdminFormsList", {
                    ref: "formsList",
                    on: { "row-selected": _vm.selectForm }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-form-data",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "View Form"
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                { attrs: { title: "Form" } },
                [_c("FormEdit", { attrs: { form: _vm.selectedForm } })],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Auditlog" } },
                [
                  _vm.selectedForm
                    ? _c("AuditLog", {
                        attrs: {
                          related_type: "form",
                          related_id: _vm.selectedForm.id
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }